import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { languageMap } from '../../tools/constants/languages';
import { languageSelectorStyles } from './styles';

interface Language {
    code: string;
    name: string;
}

interface LanguageSelectorProps {
    customerID: string;
    endpoint: string;
    requirements?: any;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ customerID, endpoint, requirements }) => {
    const { i18n, t } = useTranslation();
    const [languages, setLanguages] = useState<Language[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const clientStyles = {
        ...languageSelectorStyles.default,
        ...(languageSelectorStyles[requirements?.config?.key] || {})
      };

    const fetchLanguages = async () => {
      try {
        const url = `${endpoint}/api/jsonConfiguration/keys/${customerID}`;
        const response = await fetch(url);
        const codes: string[] = await response.json();
        const validLanguages = codes.filter(code => languageMap.hasOwnProperty(code))
            .map(code => ({
                code: code,
                name: languageMap[code]
            }));
        setLanguages(validLanguages);
      } catch (error) {
        console.error('Error fetching languages:', error);
        setError('Failed to load languages. Please try again later.');
      }
      finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
        fetchLanguages();
    }, [customerID]);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            // console.log("savedLanguage", savedLanguage);
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    const changeLanguage = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            i18n.changeLanguage(selectedOption.value);
            localStorage.setItem('selectedLanguage', selectedOption.value);
        }
    };

    const getLanguageName = () => {
        const langCode = i18n.language.split('-')[0]; // Handles codes like 'en-US' by taking 'en'
        return languageMap[langCode] || 'Select language'; // Default text if no matching entry is found
    };

    const options = languages.map(lang => ({
        value: lang.code,
        label: lang.name
    }));

    return (
        <div className="LanguageSelector">
            {isLoading ? (
                <p>{t('common.loading')}...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <Select
                    value={options.find(option => option.value === i18n.language)}
                    onChange={changeLanguage}
                    options={options}
                    isClearable={false}
                    placeholder={getLanguageName()}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={{
                        container: (baseStyles) => ({
                          ...baseStyles,
                          width: clientStyles?.container?.width,
                          minWidth: clientStyles?.container?.minWidth,
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: clientStyles?.control?.border,
                          fontWeight: clientStyles?.control?.fontWeight,
                          fontSize: clientStyles?.control?.fontSize,
                          boxShadow: clientStyles?.control?.boxShadow,
                          cursor: clientStyles?.control?.cursor,
                          textAlign: clientStyles?.control?.textAlign,
                          minHeight: clientStyles?.control?.minHeight,
                          '&:hover': {
                            backgroundColor: clientStyles?.control?.hoverBgColor,
                          }
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isSelected 
                            ? clientStyles?.option?.selectedBgColor 
                            : state.isFocused 
                              ? clientStyles?.option?.focusedBgColor 
                              : clientStyles?.option?.defaultBgColor,
                          color: clientStyles?.option?.color,
                          cursor: 'pointer',
                          ':active': {
                            backgroundColor: clientStyles?.option?.selectedBgColor
                          }
                        }),
                        menu: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: clientStyles?.menu?.boxShadow,
                            borderRadius: clientStyles?.menu?.borderRadius,
                            marginTop: clientStyles?.menu?.marginTop,
                            width: clientStyles?.menu?.width,
                            textAlign: clientStyles?.menu?.textAlign,

                        }),
                        singleValue: (baseStyles) => ({
                            ...baseStyles,
                            color: clientStyles?.singleValue?.color
                        }),
                        dropdownIndicator: (baseStyles) => ({
                            ...baseStyles,
                            color: clientStyles?.dropdownIndicator?.color,
                            ':hover': {
                                color: clientStyles?.dropdownIndicator?.hoverColor
                            }
                        }),
                        indicatorSeparator: () => ({
                            display: clientStyles?.indicatorSeparator?.display || 'none'
                        })
                    }}
                    
                />
            )}
        </div>
    );
};

export default React.memo(LanguageSelector);
