import { ImageEngineProvider } from '@imageengine/react';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Footer, Header } from './components';
import Page404 from './components/404';
import AppPopup from './components/AppPopup';
import ArticleHero from './components/ArticleHero';
import BannerImage from './components/BannerImage';
import Block from './components/Block';
import Carousel from './components/Carousel';
import EditoCarousel from './components/Carousel/EditoCarousel';
import FullScreenCarousel from './components/Carousel/FullScreenCarousel';
import ContactForm from './components/ContactForm';
import ContentShowcase from './components/ContentShowcase';
import Detours from './components/Detours';
import EditoHTML from './components/EditoHTML';
import EditoPage from './components/EditoPage';
import Engine from './components/Engine';
import EnginePage from './components/EnginePage';
import EventsSection from './components/eventsSection';
import FlexContainer from './components/FlexContainer';
import FlexContent from './components/FlexContent';
import FloatingSearchBar from './components/FloatingSearchBar';
import * as Heros from './components/Heros';
import HTMLVisualContent from './components/HTMLVisualContent';
import ImageContent from './components/ImageContent';
import ImagesGroup from './components/ImagesGroup';
import InteractiveBanner from './components/InteractiveBanner';
import InteractiveContent from './components/InteractiveContent';
import InteractiveMap from './components/InteractiveMap';
import LightSearchGrid from './components/LightSearchGrid';
import ListCategory from './components/ListCategory';
import { LoginPopup } from './components/LoginPopup';
import MiniCarousel from './components/MiniCarousel';
import Newsletter from './components/Newsletter';
import NewsletterV2 from './components/NewsletterV2';
import PartnerList from './components/PartnerList';
import Portrait from './components/Portrait';
import Quote from './components/Quote';
import ResultGrid from './components/ResultGrid';
import SearchAround from './components/SearchAround';
import Shop from './components/Shop';
import ShopSlug from './components/ShopSlug';
import ShopV2 from './components/ShopV2';
import SocialBanner from './components/SocialBanner';
import { StickyHeaderManager } from './components/StickyHeaderManager';
import TilesBlock from './components/TilesBlock';
import { TopPageNotice } from './components/TopPageNotice';
import { TravelPlaner } from './components/TravelPlaner';
import TravelPlanerHub from './components/TravelPlanerHub';
import TravelPlanerResults from './components/TravelPlanerResults';
import Video from './components/Video';
import partnerThemes from './contexts/partnerThemes';
import { SearchBoxProvider } from './contexts/SearchBoxContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { AccountItem } from './features/auth/components/AccountPanel';
import AuthStack from './features/auth/stacks/Auth';
import GuestStack from './features/auth/stacks/Guest';
import { useAuthActions, useAuthState } from './features/auth/store';
import { useFavoritesActions } from './features/favorites/store';
import { useTracking } from './tools/analytics';
import { ConfigurationUtils } from './tools/Constants';
import { RequirementsProps } from './tools/context';
import { useMetaTags } from './tools/hooks';
import { getCookies, getQueryString, replaceQueryString } from './tools/tools';

const components = {
  articlesCarousel: Carousel.Articles,
  bannerImage: BannerImage,
  editoHTML: EditoHTML,
  editoPage: EditoPage,
  heroLanding: Heros.Landing,
  heroPerso: Heros.Perso,
  heroResult: Heros.Result,
  heroSearch: Heros.Search,
  heroSuggestions: Heros.Suggestions,
  listCategory: ListCategory,
  mediaCarousel: Carousel.Media,
  editoCarousel: EditoCarousel,
  fullScreenCarousel: FullScreenCarousel,
  miniCarousel: MiniCarousel,
  newsletter: Newsletter,
  partnerList: PartnerList,
  portraitCarousel: Carousel.Portrait,
  portraitCarouselRef: Carousel.PortraitRef,
  portraitPage: Portrait,
  resultGrid: ResultGrid,
  resultGridWithCarousel: ResultGrid,
  searchCarousel: Carousel.Search,
  shop: Shop,
  staticCarousel: Carousel.Static,
  quote: Quote,
  detoursPage: Detours,
  lightSearchGrid: LightSearchGrid,
  tilesBlock: TilesBlock,
  travelPlaner: TravelPlaner,
  travelPlanerHub: TravelPlanerHub,
  travelPlanerResults: TravelPlanerResults,
  loginPopup: LoginPopup,
  htmlVisualContent: HTMLVisualContent,
  contentShowcase: ContentShowcase,
  contactForm: ContactForm,
  interactiveContent: InteractiveContent,
  interactiveMap: InteractiveMap,
  interactiveBanner: InteractiveBanner,
  flexContent: FlexContent,
  flexContainer: FlexContainer,
  imageContent: ImageContent,
  eventsSection: EventsSection,
  engine: Engine,
  enginePage: EnginePage,
  shopV2: ShopV2,
  shopSlug: ShopSlug,
  searchAround: SearchAround,
  newsletterV2: NewsletterV2,
  block: Block,
  imagesGroup: ImagesGroup,
  socialBanner: SocialBanner,
  video: Video,
};

export const PAGE_ROUTES = {
  AUTH: {
    ACCOUNT: '/compte',
    FAVORITES: '/favoris',
    NEWSLETTER: '/newsletter',
  },
  GUEST: {
    LOGIN: '/connexion',
    SIGNUP: '/inscription',
    FORGOT_PASSWORD: '/mot-de-passe-oublie',
    GOOGLE_CALLBACK: '/auth/callback/google',
  },
};

export const ACCOUNT_ITEMS: AccountItem[] = [
  {
    type: 'link',
    name: 'personalInfos',
    icon: 'userV2',
    link: PAGE_ROUTES.AUTH.ACCOUNT,
  },
  {
    type: 'link',
    name: 'favorites',
    icon: 'heartV2',
    link: PAGE_ROUTES.AUTH.FAVORITES,
  },
  {
    type: 'link',
    name: 'newsletter',
    icon: 'envelopeV2',
    link: PAGE_ROUTES.AUTH.NEWSLETTER,
  },
  {
    type: 'action',
    name: 'logout',
    icon: 'shutdown',
    action: 'logout',
  },
];

export const getPreloadedState = async (cmsData: any, requirements: RequirementsProps) => {
  const preload = {};
  const preloaders = {};

  if (!cmsData || !cmsData.content || !cmsData.content.forEach) return preload;

  cmsData.content.forEach((c) => {
    const comp = components[c._type];
    if (comp && comp.preloader) {
      preloaders[c._key] = comp.preloader(c, requirements);
    }
  });

  await Promise.all(Object.values(preloaders)).then((res) => {
    Object.keys(preloaders).forEach((p, i) => {
      preload[p] = res[i];
    });
  });

  return preload;
};

export const getHeaderTags = (props) => {
  const headers = [];

  if (!props || !props.content || !props.content.forEach) return headers;

  props.content.forEach((c) => {
    const comp = components[c._type];
    const state = props.componentStates[c._key];
    if (comp && comp.headers) {
      headers.push(...comp.headers(state));
    }
  });

  return headers;
};

export const loadCMS = async (cmsData: any, requirements: RequirementsProps) => {
  if (!cmsData || !cmsData.content || !cmsData.content.forEach) return cmsData;

  const preloaders = {};

  cmsData.content.forEach((c) => {
    const comp = components[c._type];
    if (comp && comp.cmsLoader) {
      preloaders[c._key] = comp.cmsLoader(c, requirements);
    }
  });

  await Promise.all(Object.values(preloaders)).then((res) => {
    Object.keys(preloaders).forEach((p, i) => {
      const index = cmsData.content.findIndex((c) => c._key === p);
      cmsData.content[index] = res[i];
    });
  });

  return cmsData;
};

const formatInternationalizedContent = (content: any, language: string) => {
  if (!content) return content;

  if (content.asset && content.asset._type === 'sanity.imageAsset') {
    return {
      ...content,
      asset: {
        ...content.asset,
        metadata: content.asset.metadata || {},
        altText: content.asset.altText || content.alt || '',
        title: content.asset.title || '',
        description: content.asset.description || '',
      },
    };
  }

  if (typeof content === 'string') return content;

  if (content._type === 'localeString') {
    const keys = Object.keys(content).filter((key) => key !== '_type');
    const key = keys.find((key) => language.startsWith(key)) || 'en';
    return content[key] ?? content.fr;
  }

  if (Array.isArray(content) && content[0]?._type === 'internationalizedArrayStringValue') {
    const localizedText =
      content.find((text) => language.startsWith(text._key)) ||
      content.find((text) => text._key === 'en') ||
      content.find((text) => !!text);
    return localizedText ? localizedText.value : '';
  }

  if (Array.isArray(content)) {
    return content.map((c) => formatInternationalizedContent(c, language));
  }

  if (typeof content === 'object') {
    const result = {};

    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        result[key] = formatInternationalizedContent(content[key], language);
      }
    }

    return result;
  }

  return content;
};

export interface OverLayParameter {
  key: 'loginPopup';
  context?: any;
}

const App = (baseProps: any) => {
  const props = formatInternationalizedContent(baseProps, i18next.language);
  const categories = props.configuration?.categories || {};

  useMetaTags({
    metaTitle: props.seo?.metaTitle || props.metaTitle || props.title,
    metaDescription: props.seo?.metaDescription || props.metaDescription || props.description,
    metaImage: props.seo?.metaImage,
    openGraphTitle: props.seo?.openGraphTitle || props.openGraphTitle,
    openGraphImage: props.seo?.openGraphImage || props.openGraphImage,
    keywords: props.seo?.keywords || props.keywords,
    noIndex: props.seo?.noIndex || props.noIndex,
  });

  const currentPartner = props.currentPartner || props.requirements?.partner || 'default';
  const [overlay, setOverlay] = useState<OverLayParameter>();
  const { initTracking, trackEvent } = useTracking();
  const authActions = useAuthActions();
  const favoritesActions = useFavoritesActions();
  const authState = useAuthState();

  useEffect(() => {
    if (!props.withAuth) return;
    authActions?.init();
    favoritesActions?.init();
  }, [authActions, favoritesActions, props.withAuth]);

  useEffect(() => {
    initTracking();
    const cookies = getCookies();
    if (cookies.loginToken) {
      if (getQueryString('successLoginPhone')) {
        trackEvent('success_login_phone');
        replaceQueryString('successLoginPhone', null);
      }
      if (getQueryString('successLoginGoogle')) {
        trackEvent('success_login_google');
        replaceQueryString('successLoginGoogle', null);
      }
    }

    const root = document.querySelector(':root') as HTMLDivElement;
    if (typeof props.siteColor !== 'undefined')
      Object.keys(props.siteColor).forEach((sc) =>
        root.style.setProperty(`--${sc}`, props.siteColor[sc])
      );
    if (typeof props.siteFont !== 'undefined')
      Object.keys(props.siteFont).forEach((sc) =>
        root.style.setProperty(`--${sc}`, props.siteFont[sc])
      );

    const askUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            localStorage.setItem('user-location', JSON.stringify(position.coords));

            const { latitude, longitude } = position.coords;
            reverseGeocoding(latitude, longitude).then((data) => {
              const city = data?.address?.city || data?.address?.town || data?.address?.village;

              if (!city) return;

              localStorage.setItem('location', city);
            });
          },
          (positionError) => {
            console.error('Error getting user location', positionError);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 6000,
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    };

    if (props?.askForLocation) askUserLocation();
  }, [props.siteColor, props.siteFont, trackEvent, initTracking, props?.askForLocation]);

  const reverseGeocoding = async (latitude: number, longitude: number) => {
    if (!latitude || !longitude) return;

    const params = {
      lat: String(latitude),
      lon: String(longitude),
      format: 'json',
    };

    const url = `https://nominatim.openstreetmap.org/reverse?${new URLSearchParams(params)}`;
    try {
      const response = await fetch(url);
      return response.json();
    } catch (error) {
      console.error('Error fetching reverse geocoding', error);
    }
  };

  const guestPages = Object.values(PAGE_ROUTES.GUEST);
  const authPages = Object.values(PAGE_ROUTES.AUTH);

  const handleGetComponent = (c: any) => {
    const comp = components[c._type];
    if (!comp) return <div key={c._key}>Error no component render : {c._type}</div>;

    if (c._type === 'heroSuggestions') {
      console.log('Hero Suggestions props:', {
        backgroundImage: c.backgroundImage,
        asset: c.backgroundImage?.asset,
      });
    }

    const Comp = comp.Renderer || comp;

    return (
      <Comp
        {...c}
        key={c._key}
        sellerState={props.sellerState}
        requirements={props.requirements}
        componentStates={props.componentStates[c._key]}
        navigation={props.navigation}
        headerButtons={props.headerButtons}
        setOverlay={setOverlay}
        filtersConfiguration={props.filtersConfiguration}
        siteConfiguration={props.siteConfiguration}
        categories={categories}
      />
    );
  };

  const handleOverlay = () => {
    if (!overlay || !overlay.key) return null;

    const comp = components[overlay.key + ''];
    const Comp = comp.Renderer || comp;
    return <Comp setOverlay={setOverlay} context={overlay.context} {...props} />;
  };

  const ContentWrapper = ({ children }) => {
    if (props._type === 'article') return <div className="Article__content">{children}</div>;

    return children;
  };

  if (props?.configuration) ConfigurationUtils.setContext(props.configuration);

  const Page = props.content?.length ? (
    <StickyHeaderManager>
      <ThemeProvider
        value={{
          partner: currentPartner,
          colors: partnerThemes[currentPartner] || partnerThemes['default'],
        }}
      >
        <SearchBoxProvider>
          <ImageEngineProvider deliveryAddress="https://tourismebyca.twic.pics/">
            <TopPageNotice notice={props.topPageNotice} requirements={props.requirements} />
            <div className="SSRApp">
              {!props.header?.hidden && (
                <Header
                  navigation={props.navigation}
                  requirements={props.requirements}
                  header={props.header}
                  headerButtons={props.headerButtons}
                  headerType={props.header?.type || 'classic'}
                />
              )}
              {props.hero?.map(handleGetComponent)}
              {props._type === 'article' ? <ArticleHero {...props} /> : null}
              <ContentWrapper>{props.content.map(handleGetComponent)}</ContentWrapper>
              {handleOverlay()}
              {!props.footer?.hidden && (
                <Footer
                  requirements={props.requirements}
                  footer={props.footer}
                  footerType={props.footerOptions?.type || 'classic'}
                />
              )}
              {props.popups &&
                props.popups.map((popup: any) => <AppPopup key={popup._id} {...popup} />)}
            </div>
          </ImageEngineProvider>
          {props.requirements.config.activeFloatingSearchBar && (
            <FloatingSearchBar
              placeholderSentences={props.siteConfiguration.placeholderSentences}
            />
          )}
        </SearchBoxProvider>
      </ThemeProvider>
    </StickyHeaderManager>
  ) : (
    <StickyHeaderManager>
      <Page404
        requirements={props.requirements}
        navigation={props.navigation}
        header={props.header}
        headerButtons={props.headerButtons}
        headerType={props.header.type}
        footerType={props.footerOptions?.type || 'classic'}
      />
    </StickyHeaderManager>
  );

  // Si le type de la page est dans PageTypes et que props.pageWithAuth est false, on ne passe pas par la stack d'authentification (ça veut dire que la page est accessible sans authentification) => pour le SEO (SSR)
  const PageTypes = ['page', 'article'];
  if (PageTypes.includes(props._type) && !props.pageWithAuth) return Page;

  if (!!props.withAuth) {
    if (authState === 'loading') return <div />;

    if (guestPages.includes(window.location.pathname)) {
      if (authState === 'loggedIn') {
        const redirect = getQueryString('redirect');
        if (redirect) window.location.href = decodeURIComponent(redirect);
        else window.location.href = PAGE_ROUTES.AUTH.ACCOUNT;
      }

      return (
        <StickyHeaderManager>
          <GuestStack
            authConfig={props.authConfig}
            requirements={props.requirements}
            navigation={props.navigation}
            header={props.header}
            headerButtons={props.headerButtons}
            headerType={props.header.type}
            footerType={props.footerOptions?.type || 'classic'}
          />
        </StickyHeaderManager>
      );
    }

    if (authPages.includes(window.location.pathname)) {
      if (authState === 'guest') window.location.href = PAGE_ROUTES.GUEST.LOGIN;

      return (
        <StickyHeaderManager>
          <AuthStack
            authConfig={props.authConfig}
            requirements={props.requirements}
            navigation={props.navigation}
            header={props.header}
            headerButtons={props.headerButtons}
            headerType={props.header.type}
            footerType={props.footerOptions?.type || 'classic'}
          />
        </StickyHeaderManager>
      );
    }

    if (!!props.pageWithAuth && authState === 'guest') {
      const pathWithQueries = window.location.pathname + window.location.search;
      window.location.href = `${PAGE_ROUTES.GUEST.LOGIN}?redirect=${encodeURIComponent(pathWithQueries)}`;
    }
  }

  return Page;
};

export default App;
